import React, { useEffect, useState } from 'react';
import Button from '../../components/button/Button';
import { AudioRecorderDefaultConfig } from '../../components/audioRecorder/AudioRecorder';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import ICMSTAudioVisualizer from '../../components/audioVisualizer/AudioVisualizer';
import { isSilenceDetectedForGivenTime } from '../../service/MicService';
import { uploadAudioFile } from '../../api/authApis';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const SpeakCheckScreen = (props: any) => {
    const testTakerDetails = useSelector((state: RootState) => state.appConfigSlice.testTaker);
    const [isRecordingStarted, setIsRecordingStarted] = useState(false);
    const [errorScreen, setErrorScreen] = useState(false);
    const [ uploadBlob , setUploadBlob ] = useState(false);

    let audioRecorder = useAudioRecorder({
        ...AudioRecorderDefaultConfig,
        deviceId: props?.selectedMic || 'default',
    });


    useEffect(() => {
        if (!isRecordingStarted && audioRecorder?.recordingTime > 0) {
            audioRecorder?.stopRecording()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecordingStarted]);

    useEffect(() => {
        if( audioRecorder?.recordingBlob && uploadBlob) {
            let fileBlob = audioRecorder.recordingBlob? audioRecorder.recordingBlob : new Blob(audioRecorder.recordingBlob);
            let fileFormat = ".mp3";
            let fileName = "speaking_test";
            uploadAudioFile(fileBlob, fileFormat, fileName, testTakerDetails);   
            props?.setIsSpeakCheckFinished(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioRecorder?.recordingBlob])
    
    useEffect(() => {
        const fetchData = async () => {
            if( audioRecorder?.mediaRecorder ) {
                let output = await isSilenceDetectedForGivenTime(audioRecorder, 8000, props?.silenceMeanValue);
                if (!output) {
                    setUploadBlob(true)
                    onStop();
                } else {
                    setErrorScreen(true)
                    setUploadBlob(false)
                    onStop()
                }
                console.log('output: ', output);
            }
        };
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioRecorder?.mediaRecorder]);

    const stop = async () => { 
        audioRecorder.stopRecording();
    }

    useEffect(() => {
        if( props.prevButtonClicked) {
            stop();
            props.setPrevCallBackDone(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ props.prevButtonClicked])

    const onStart = async () => {
        setIsRecordingStarted(true);
        audioRecorder?.startRecording()
    }

    const onStop = async () => {
        setIsRecordingStarted(false);
    }

    return (
        <>
            {errorScreen ?
                (
                    <div>
                        <p>
                            We can't hear you . 
                        </p>
                        <p>
                            This may be because you are speaking too quietly, or because there is too much noise around you.
                        </p>
                        <b>Please move closer to the microphone or try speaking more loudly.</b>
                        <div className='buttonBlk'>
                            <Button
                                label="Try Again"
                                onClick={() => setErrorScreen(false)}
                            />
                        </div>
                    </div>
                )
                :
                (
                    <div>
                        <p>
                            Now, lets check your microphone.
                        </p>
                        <b>
                            Press 'Start' button and read the below sentence
                        </b>
                        <div className='buttonBlk'>
                            <Button
                                label={isRecordingStarted
                                    ? <ICMSTAudioVisualizer
                                        mediaRecorder={audioRecorder?.mediaRecorder}
                                        barColor={'#ffffff'}
                                    />
                                    : 'Start'}

                                onClick={onStart}
                            />
                        </div>
                        <h2>I am testing my microphone, one, two, three. Soon there will be a koala in front of me.</h2>
                    </div>
                )}
        </>
    )
}


export default SpeakCheckScreen;
