import { getHeader, postHeader, uploadAudioFileDetails } from "./apiOptions";
import { fetchData, postData } from "./apiService";
import { apiUrl } from "../constants/constants";
import { getFormattedDate } from "../service/utils";

// Get data from the API using the getHeader function
export const getData = async () => {
  try {
    const data = await fetchData(apiUrl.GET_P1_QUESTIONS, { headers: getHeader()}, "cmst", {});
    console.log("Fetched data:", data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Post data to the API using the postHeader function
export const uploadAudioFile = async (fileBlob: Blob, fileFormat: string, fileName: string, testTakerDetails: any) => {
  try {
    let data = uploadAudioFileDetails(fileBlob, fileFormat || ".mp3", fileName || "", testTakerDetails );
    await postData(
      apiUrl.UPLOAD_AUDIO_FILE, data,
      { headers:{ ...postHeader(fileFormat),  "Authorization": 'Bearer ' + testTakerDetails.testTakerAccessToken} },
      "cmst"
    );
  } catch (error) {
    console.error("Error posting data:", error);
  }
};

export const getContentData = async ( testTakerDetails: any) => {
  try {
    const response = await postData(
      apiUrl.GET_CONTENT_DATA,
      {
        "testTakerId" : testTakerDetails?.testTakerId,
        "centreName" : testTakerDetails?.testTakerCenterName,
        "langAccent" : testTakerDetails?.testTakerLanguage,
        "testTakerDate" : getFormattedDate(new Date()),
        "uniqueSessionId": testTakerDetails?.testTakerSessionId
      },
      { headers: { ...getHeader(), "Authorization": 'Bearer ' + testTakerDetails?.testTakerAccessToken}},
      "atc"
    );
    return response?.data;
  } catch (error) {
    console.error("Error fetching content data:", error);
    throw error;
  }
}

export const getPart3ContentData = async ( testTakerDetails: any) => {
  try {
    const response = await postData(
      apiUrl.GET_P3_QUESTIONS,
      {
        "testTakerId" : testTakerDetails?.testTakerId,
        "centreName" : testTakerDetails?.testTakerCenterName,
        "langAccent" : testTakerDetails?.testTakerLanguage,
        "testTakerDate" : getFormattedDate(new Date()),
        "uniqueSessionId": testTakerDetails?.testTakerSessionId
      },
      { headers: { ...getHeader(), "Authorization": 'Bearer ' + testTakerDetails?.testTakerAccessToken}},
      "atc"
    );
    return response?.data;
  } catch (error) {
    console.error("Error fetching content data:", error);
  }
}

export const getProbes = async ( testTakerDetails: any ) => {
  try {
    const response = await postData(
      apiUrl.GET_PROBES,
      {
        "testTakerId" : testTakerDetails?.testTakerId,
        "centreName" : testTakerDetails?.testTakerCenterName,
        "langAccent" : testTakerDetails?.testTakerLanguage,
        "testTakerDate" : getFormattedDate(new Date()),
        "uniqueSessionId": testTakerDetails?.testTakerSessionId
      },
      { headers: {...getHeader(), "Authorization": 'Bearer ' + testTakerDetails?.testTakerAccessToken}},
      "atc"
    );
    return response?.data;
  } catch (error) {
    console.error("Error fetching probe data:", error);
  }
}

export const fetchTestTakerSessionId = async ( testTakerDetails: any ) => {
  try {
    return await fetchData(apiUrl.GET_SESSION_ID_URL, { headers: {...getHeader(), "Authorization": 'Bearer ' + testTakerDetails?.testTakerAccessToken}}, "cmst", {});
  } catch (error) {
    console.error("Error fetching fetchTestTakerSessionId:", error);
  }
}