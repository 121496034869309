import React from 'react';
import './SampleContent.css';
// import parse from 'html-react-parser';

const SampleContent = (props: any) => {
    // Extract the screenName prop from the parent component
    const { 
        screenName, 
        // text
    } = props;
    let sampleContent;
    // Switch statement to render content based on the screenName prop
    // Render different content based on the screenName prop value
    // For example, the FontScreen and ContrastScreen components display instructions for font size and colour mode selection respectively
    // Other components could be added as per the requirements of the test interface design
    switch(screenName) {
        case 'FontScreen':
        case 'ContrastScreen':
            sampleContent = (
                <div className='sampleScreenBlock'>
                    <b>
                        { 
                            screenName === "FontScreen" ? 
                            "Pick your font size by clicking the buttons on the left-hand side panel" 
                            :
                            "Pick your colour mode by clicking the buttons on the left-hand side panel"
                        }
                        <ul>
                            <li>{screenName === "FontScreen" ? "Small" : "Black on white"}</li>
                            <li>{screenName === "FontScreen" ? "Medium" : "White on black"}</li>
                            <li>{screenName === "FontScreen" ? "Large" : "Yellow on black"}</li>
                        </ul>
                        Make sure the text is clear and comfortable to read.
                    </b> 
                </div>
            );
        break;
        case 'AvatarScreen':
            sampleContent = (
                <div className='sampleScreenBlockPart2'>
                    {/* {parse(text)} */}
                    <h2>Describe the time of day you enjoy most.</h2>
                    <div>
                        <b>
                            You should say:
                            <ul>
                                <li>What time of day it is</li>
                                <li>What you normally do at this time</li>
                                <li>What other people do at this time</li>
                            </ul>
                            and explain why you particularly enjoy this time of day.
                        </b> 
                    </div>
                </div>
            );
        break;
        default:
            sampleContent = (
                <div className='sampleScreenBlock'>
                    <h2>Welcome to the Speaking Test!</h2>
                    <p>
                        <b>Audrey will guide you through the three parts of the test.</b> 
                    </p> 
                    <p>
                        <b>Please note that the recording will begin as soon as the test starts.</b> 
                    </p>
                </div>
            );
    }
    return sampleContent;
}

export default SampleContent;
