import React, { useEffect, useRef, useState } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { AudioRecorderDefaultConfig } from "../../components/audioRecorder/AudioRecorder";
import Card from "../../components/card/Card";
import AvatarScreen from "../avatarScreen/AvatarScreen";
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import './TestScreen.css';
import ICMSTAudioVisualizer from "../../components/audioVisualizer/AudioVisualizer";
import { getContentData, getPart3ContentData, getProbes, uploadAudioFile } from "../../api/authApis";
import { isSilenceDetectedForGivenTime } from "../../service/MicService";
import { formatDate } from "../../service/utils";
import {
    animationModes,
    audioPlayReasons,
    MAX_DURATION,
    MIN_DURATION,
    RULE_FOUR_MAX_COUNTER,
    RULE_ONE_SILENCE_DURATION,
    RULE_THREE_MIN_DURATION,
    RULE_TWO_SILENCE_DURATION
} from "../../constants/constants";
import { frameTestQuestions } from "../../service/QuestionService";
const TestScreen = () => {
    // Settings or configurations needed for taking test
    const {
        selectedMic, appFontSize, testTaker, silenceMeanValue
    } = useSelector((state: RootState) => state.appConfigSlice);
    const audioContextRef = useRef<AudioContext>();
    const mediaRecorderRef = useRef<MediaRecorder>()
    const destinationRef = useRef<MediaStreamAudioDestinationNode>();
    const [fullRecordingBlob, setFullRecordingBlob] = useState();
    const [currentQuestionInfo, setCurrentQuestionInfo] = useState<any>(null);
    const [userAudioFileName, setUserAudioFileName] = useState<any>('');
    const [questionList, setQuestionList] = useState<any>([]);
    const [playIndex, setPlayIndex] = useState(0);
    const [probeData, setProbeData] = useState<any>([]);
    const [currentPart, setCurrentPart] = useState('part1');
    const [showTimer, setShowTimer] = useState(false);
    let timeOutRef = useRef<number | NodeJS.Timeout>()
    let speakingTimeRef = useRef(0);
    let silentTimeRef = useRef(0);
    let itterationCountRef = useRef(0);
    let questionIndexRef = useRef(0)
    let responseIntervalRef = useRef<number | NodeJS.Timeout>()
    let audiRecorder2Ref = useRef<any>();
    let partIndexRef = useRef<any>();
    let lastSilentDetectionRef = useRef<any>(true);
    let repeatClipRef = useRef<any>({ status: audioPlayReasons.NOT_PLAYING, counter: 0 });
    let probeRef = useRef<any>({ status: audioPlayReasons.NOT_PLAYING, index: 2 });
    const [loading, setLoading] = useState(false);
    let fullRecording = useAudioRecorder({
        ...AudioRecorderDefaultConfig.audioTrackConstraints,
        deviceId: selectedMic || 'default',
    });
    audiRecorder2Ref.current = useAudioRecorder({
        ...AudioRecorderDefaultConfig.audioTrackConstraints,
        deviceId: selectedMic || 'default',
    });

    // Initialize the played audio files array
    let playedAudioFilesArray = useRef<any>([]);

    // Set the audio player state to playing or not playing
    let isAudioPlaying = false;

    // Set the animation mode by default to idle
    const modeRef = useRef<string | undefined>();

    // Set the varient ref by default to notPlaying and idx to 0
    let variantRef = useRef<any>({ status: audioPlayReasons.NOT_PLAYING, idx: 0 });
    let timerRef = useRef<any>();
    let cardTextRef = useRef<any>();

    // Adjust the waveform height based on selected font size
    let waveFormHeight;
    switch(appFontSize){
        case "small":
            waveFormHeight = 16;
            break;
        case "medium":
            waveFormHeight = 18;
            break;
        case "large":
            waveFormHeight = 20;
            break;
        default:
            waveFormHeight = 20;
            break;
    }
    /* 
        When `showTimer` is true, the countdown starts, decrementing `timerRef.current` every second.
    * - A `setInterval` function is used to update the timer value every 1000ms (1 second).
    * - When `timerRef.current` reaches 0:
    *   1. The interval is cleared using `clearInterval` to stop the countdown.
    *   2. `setShowTimer(false)` is called to indicate that the timer has ended
    */
    useEffect(() => {
        if( showTimer ) {
            let intvalId = setInterval(() => {
                timerRef.current = timerRef.current - 1;
                if( timerRef.current === 0 ) {
                    clearInterval(intvalId);
                    setShowTimer(false)
                }
            }, 1000)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ showTimer ])

    /* sets the current question and play the question audio
    - Speaking ref, silent ref, repeat ref, variant ref are re-initialized here for new question
    - questionIndex ref is increased as this is the place for moving to next question
    - if question is card statement , then it will assign the markdown text to the cardText ref
    - if reaches the full length of question list, then stops the full recording
    */
    useEffect(() => {
        if (currentQuestionInfo && currentQuestionInfo !== null) {
            playAudio(currentQuestionInfo);
            if (repeatClipRef.current?.status === audioPlayReasons.NOT_PLAYING) {
                itterationCountRef.current = 0;
                speakingTimeRef.current = 0;
                silentTimeRef.current = 0;
                questionIndexRef.current = questionIndexRef.current + 1;
                repeatClipRef.current = { status: audioPlayReasons.NOT_PLAYING, counter: 0 }
                variantRef.current = { status: audioPlayReasons.NOT_PLAYING, idx: 0 }
            } else {
                console.log('Avatar is repeating the same question');
            }
        } else {
            if (partIndexRef?.current?.[(playIndex)]) {
                setCurrentPart(partIndexRef?.current[(playIndex)])
            }
            setCurrentQuestionInfo(questionList[playIndex]);
            if( questionList[playIndex]?.showTimer) {
                cardTextRef.current = questionList[playIndex]?.markDownText; 
            }
            if (playIndex === questionList.length) {
                stopFullRecording();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuestionInfo])

    // upload the audio to s3 of the full recording.
    useEffect(() => {
        if (fullRecordingBlob) {
            uploadAudioFile(fullRecordingBlob, ".mp3", `fullrecording`, testTaker);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullRecordingBlob])

    /* fetch content data 
    - Get part 1 and part 2 data from one api
    - Get part 3 content from different api
    */
    const fetchContent = async () => {
        setLoading(true);
        let res = await getContentData(testTaker);
        let part3Res = await getPart3ContentData(testTaker);
        setLoading(false)
        return { part1And2Res: res, part3Res };
    }

    // Once the whole test is done , this will get execute
    const stopFullRecording = () => {
        fullRecording?.stopRecording();
        mediaRecorderRef?.current?.stop()
    }

    // stops the user audio and remove the timeout
    const stopRecording = (fromSource: any) => {
        // reason putting for if condition is as settime interval running one time after stoping the recording
        if (playIndex === questionIndexRef.current - 1) {
            clearInterval(responseIntervalRef.current)
            audiRecorder2Ref?.current?.stopRecording();
            clearTimeout(timeOutRef.current);
            let fileName = `${playIndex + 1}_${formatDate(new Date())}`;
            setUserAudioFileName(fileName);
            if( currentQuestionInfo?.monologueQuestion) {
                cardTextRef.current = '';
            }
            if (fromSource === 'window' && lastSilentDetectionRef.current === false && !currentQuestionInfo?.showTimer) {
                interuptClip(audioPlayReasons.MAX_DURATION_REACHED)
            } else {
                setCurrentQuestionInfo(null);
                if (playIndex < questionList.length - 1) {
                    setPlayIndex(playIndex + 1);
                } else {
                    setPlayIndex(playIndex + 1);
                    stopFullRecording()
                }
            }
        }
    }

    // handles all animation modes here
    const handleAnimationModes = (mode: any) => {
        modeRef.current = mode;
        console.log('Avatar is ', mode);
    }

    /*
    For part 3 instead of repeat, variant will be played
    - Using variant index ref, it will play the variant sequentially
    - while playing the variants, rules will be off
    - When there is no variant, stops the recording and moved to next prompt
    */
    const playVariant = (reason: any) => {
        let variantIndex = variantRef.current.idx;
        if (parseInt(variantIndex) < currentQuestionInfo?.variants?.length) {
            variantRef.current = { status: audioPlayReasons.PLAYING, idx: variantIndex }
            playProbe(currentQuestionInfo?.variants[variantIndex]?.localizedMedia, () => {
                variantRef.current = { status: audioPlayReasons.NOT_PLAYING, idx: (parseInt(variantIndex) + 1) };
                speakingTimeRef.current = 0;
                silentTimeRef.current = 0;
            }, reason);
        }
        else {
            stopRecording('End of Variant')
        }
    }

    /*
    This function will be called on all audios played
    - Here we are the logging the audio path, time of audio played, reasons for audio played
    */
    const handlePlayedAudioFiles = (questionInfo: any, reason: string, type: string) => {
        let audioFileURL = type === 'prompt' ?
            (questionInfo?.localizedMedia?.audioPathUrl + "/" + questionInfo?.localizedMedia?.audioRef)
            :
            (questionInfo?.audioPathUrl + "/" + questionInfo?.audioRef);

        let playedAudioFileObj = {
            "audioFileURL": audioFileURL,
            "audioFilePlayDateTime": formatDate(new Date()),
            "audioFilePlayReason": reason || audioPlayReasons.QUESTION_COMPLETED
        }
        playedAudioFilesArray.current = [...playedAudioFilesArray.current, playedAudioFileObj];
        console.log("Played_Audio_Files_Obj: ", playedAudioFilesArray.current);
    }

    // playss the audio provided as argument
    const playAudio = (questionInfo: any) => {
        let audioUrl = questionInfo?.localizedMedia?.audioPreSignUrl;
        const audio = new Audio(audioUrl);
        // playback audio volume is reduced , so in case of high sound in system, echo will occur in recording
        // this is temporary solution to test in different env 
        audio.volume = 0.6;
        audio.crossOrigin = "anonymous";
        const sourceNode = audioContextRef.current?.createMediaElementSource(audio);
        if (destinationRef.current) {
            sourceNode?.connect(destinationRef.current);
        }
        if (audioContextRef?.current) {
            sourceNode?.connect(audioContextRef?.current?.destination)
        }
        if (questionInfo?.expectedMaxDuration !== 0 && questionInfo?.expectedMaxDuration !== null) {
            if ( !questionInfo?.showTimer ) {
                timeOutRef.current = setTimeout(() => {
                    stopRecording('window');
                }, questionInfo?.expectedMaxDuration ? questionInfo?.expectedMaxDuration * 1000 : MAX_DURATION)
            }
        }
        audio.play()
            .then((res: any) => {
                isAudioPlaying = true;

                // handle talking mode here
                handleAnimationModes(animationModes.TALKING);

                // Call to handle the played audio files
                handlePlayedAudioFiles(questionInfo, audioPlayReasons.QUESTION_COMPLETED, "prompt");
            })
            .catch(error => {
                isAudioPlaying = false;
                handleAnimationModes(animationModes.IDLE);
                console.log('Playing inside catch', error)
            });
        audio.onended = () => {
            isAudioPlaying = false;
            handleAnimationModes(animationModes.IDLE);
            if (repeatClipRef.current?.status === audioPlayReasons.PLAYING) {
                repeatClipRef.current = { status: audioPlayReasons.NOT_PLAYING, counter: repeatClipRef.current?.counter };
            }
            if (questionInfo?.expectedMaxDuration !== 0 && questionInfo?.expectedMaxDuration !== null) {
                if (!questionInfo?.showTimer) {
                    audiRecorder2Ref?.current.startRecording();
                } else {
                    timeOutRef.current = setTimeout(() => {
                        stopRecording('window');
                    }, questionInfo?.expectedMaxDuration ? questionInfo?.expectedMaxDuration * 1000 : MAX_DURATION)
                    setShowTimer(true);
                    timerRef.current = questionInfo?.expectedMaxDuration;
                }
            } else {
                setCurrentQuestionInfo(null)
                setPlayIndex(playIndex + 1)
            }
        }

    }

    // Frames the question for part all parts
    const frameQuestions = async (contentData: any) => {
        let { questionList = [], partIdx = {} } = await frameTestQuestions(contentData);
        setQuestionList(questionList);
        partIndexRef.current = partIdx;
        setCurrentQuestionInfo(questionList[0]);

    }

    const fetchProbeData = async () => {
        let res = await getProbes(testTaker);
        return res;
    }

    // Implement the logic for the TestScreen component here.
    const initialize = async () => {
        fullRecording?.startRecording();
        let contentData = await fetchContent();
        let probeData = await fetchProbeData();
        setProbeData(probeData?.Probes);
        frameQuestions(contentData)
    }

    useEffect(() => {
        initialize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // full recording includes avatar and user audio. On end returns the blob
    useEffect(() => {
        if (fullRecording?.mediaRecorder) {
            audioContextRef.current = new AudioContext();
            destinationRef.current = audioContextRef.current.createMediaStreamDestination();
            const micSource = audioContextRef.current.createMediaStreamSource(fullRecording?.mediaRecorder?.stream);
            micSource.connect(destinationRef.current);
            mediaRecorderRef.current = new MediaRecorder(destinationRef.current.stream);
            mediaRecorderRef.current.ondataavailable = (event: any) => {
                setFullRecordingBlob(event.data)
            };
            mediaRecorderRef.current.start();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [fullRecording?.mediaRecorder]);

    const playProbe = (probClip: any, callbackOnEnd: any, reason: string) => {
        const audio = new Audio(probClip?.audioPreSignUrl);
        audio.volume = 0.6;
        audio.crossOrigin = "anonymous";
        const sourceNode = audioContextRef.current?.createMediaElementSource(audio);
        if (destinationRef.current) {
            sourceNode?.connect(destinationRef.current);
        }
        if (audioContextRef?.current) {
            sourceNode?.connect(audioContextRef?.current?.destination)
        }
        audio.play()
            .then(() => {
                isAudioPlaying = true;
                /**
                 * Animation's talking mode is used for both talking and encourage modes, 
                 * as encourage animation mode is not supported in current animation
                 * so animation's encourage mode is removed
                */
                handleAnimationModes(animationModes.TALKING);

                // Call to handle the played audio files
                handlePlayedAudioFiles(probClip, reason, "probe");
            })
            .catch(error => {
                isAudioPlaying = false;
                handleAnimationModes(animationModes.IDLE);
                console.log('Playing inside catch', error)
            });
        audio.onended = () => {
            isAudioPlaying = false;
            handleAnimationModes(animationModes.IDLE);
            callbackOnEnd();
        }
    }

    const repeatClip = (reason: any) => {
        console.log('Reason for repeat', reason);
        silentTimeRef.current = 0;
        speakingTimeRef.current = 0;
        repeatClipRef.current = { status: audioPlayReasons.PLAYING, counter: repeatClipRef.current?.counter + 1 };
        let probeClipInfo = probeData?.find((ele: any) => ele.probeKey === "pb2")
        if (probeClipInfo) {
            playProbe(probeClipInfo, () => {
                setCurrentQuestionInfo({ ...currentQuestionInfo });
            }, reason)
        }
    }

    const interuptClip = (reason: any) => {
        console.log('Reason for interupt', reason);
        let probeClipInfo = probeData?.find((ele: any) => ele.probeKey === "pb1")
        if (probeClipInfo) {
            playProbe(probeClipInfo, () => {
                setCurrentQuestionInfo(null);
                if (playIndex < questionList.length - 1) {
                    setPlayIndex(playIndex + 1);
                } else {
                    setPlayIndex(playIndex + 1);
                    stopFullRecording()
                }
            }, reason)
        }
    }


    const encourage = () => {
        let probeIndex = probeRef?.current?.index;
        probeRef.current = { status: audioPlayReasons.PLAYING, index: probeIndex };
        silentTimeRef.current = 0;
        let probeClipInfo = probeData[probeIndex]
        if (probeClipInfo) {
            playProbe(probeClipInfo, () => {
                if (probeIndex === probeData?.length - 1) {
                    probeIndex = 2;
                } else {
                    probeIndex += 1
                }
                probeRef.current = { status: audioPlayReasons.NOT_PLAYING, index: probeIndex }
            }, audioPlayReasons.NOT_MUCH_RESPONDED)
        }
    }

    //check for all rules 
    const checkForRules = (isSilence: any) => {
        let maxDuration = currentQuestionInfo?.expectedMaxDuration ? currentQuestionInfo?.expectedMaxDuration * 1000 : MAX_DURATION;
        let minDuration = currentQuestionInfo?.expectedMinDuration ? currentQuestionInfo?.expectedMinDuration : MIN_DURATION;
        // Encourage
        if (
            itterationCountRef.current < (minDuration * 2)
            && speakingTimeRef?.current / 2 >= 1  // some response should be there
            && silentTimeRef?.current / 2 > RULE_THREE_MIN_DURATION - .5
            && currentPart === "part3"
        ) {
            encourage()
        }
        // Move On
        if (
            itterationCountRef.current * 500 > maxDuration // checks whether duration is less than maxDuration
            || (isSilence && (itterationCountRef.current / 2 > minDuration) && speakingTimeRef?.current / 2 >= 1 && (silentTimeRef.current / 2 > RULE_TWO_SILENCE_DURATION - .5))
        ) {
            clearInterval(responseIntervalRef.current);
            if (silentTimeRef.current > 0)
                console.log(`Silent for ${silentTimeRef.current / 2} seconds`)
            stopRecording('On Voice end');
        }

        // Repeat
        if (
            repeatClipRef.current?.status === audioPlayReasons.NOT_PLAYING
            && itterationCountRef.current * 500 < maxDuration
            && (speakingTimeRef.current === 0 && silentTimeRef.current / 2 > RULE_ONE_SILENCE_DURATION - .5)
            && variantRef?.current?.status === audioPlayReasons.NOT_PLAYING
        ) {
            if (
                currentPart === "part1"
                && repeatClipRef.current?.counter < RULE_FOUR_MAX_COUNTER
            ) {
                repeatClip('No response');
            }
            if (currentPart === "part3") {
                playVariant("No response");
            }
        }
    }

    // User recording is enabled 
    useEffect(() => {
        // This will be executed on once user recording is started for the prompt
        if (audiRecorder2Ref?.current?.mediaRecorder) {
            const fetchSilence = async () => {
                // This interval results whether user is speaking or in silent mode for every 500 milli second to maxDuration
                responseIntervalRef.current = setInterval(async () => {
                    if (audiRecorder2Ref?.current?.mediaRecorder) {
                        itterationCountRef.current = itterationCountRef.current + 1;
                        if (repeatClipRef.current?.status === audioPlayReasons.NOT_PLAYING && probeRef?.current?.status === audioPlayReasons.NOT_PLAYING && variantRef.current.status === audioPlayReasons.NOT_PLAYING) {
                            lastSilentDetectionRef.current = await isSilenceDetectedForGivenTime(audiRecorder2Ref?.current, 500, silenceMeanValue || 0.01544762491032353)
                            if (!lastSilentDetectionRef.current) {
                                handleAnimationModes(animationModes.LISTENING);
                                speakingTimeRef.current = speakingTimeRef.current + 1;
                                silentTimeRef.current = 0;
                            } else {
                                if (modeRef.current !== animationModes.TALKING && !isAudioPlaying) {
                                    handleAnimationModes(animationModes.IDLE)
                                }
                                silentTimeRef.current = silentTimeRef.current + 1;
                                // speakingTimeRef.current = 0; commenting this line if in case speaking time needs to be reset to 0 if silence
                            }
                        }
                        // passing silence value to function
                        checkForRules(lastSilentDetectionRef.current)
                    }
                }, 500)
            }
            fetchSilence()
        }

        // This will execute ones the user recording is done for particular prompt and before the playing
        // the next audio
        if (audiRecorder2Ref?.current?.recordingBlob && !audiRecorder2Ref?.current?.mediaRecorder) {
            let fileBlob = audiRecorder2Ref?.current?.recordingBlob;
            let fileFormat = ".mp3";
            uploadAudioFile(fileBlob, fileFormat, userAudioFileName, testTaker);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audiRecorder2Ref?.current?.mediaRecorder]);

    // Code to add the border to highlight the avatar animation while avatar is talking
    let avatarBorderClass = useRef("avatarBorder");
    return (
        <div>
            <div className={`testScreen vertical-center`}>
                <div className={`flex fitContentCenterCard`}>
                    {!loading ? (
                        <div>
                            { ! cardTextRef.current && 
                                <Card
                                    content={
                                        <div>
                                            <AvatarScreen mode={modeRef.current} />
                                        </div>
                                    }
                                    cardClassName={`${modeRef.current === "talking" ? avatarBorderClass.current : ''}`}
                                />
                            }
                            { cardTextRef.current && 
                                <div>
                                    <AvatarScreen 
                                        mode={modeRef.current} 
                                        currentPart={currentPart} 
                                        cardTextRef={cardTextRef}
                                        timerValue={timerRef.current}
                                        showTimer={showTimer}
                                    />
                                </div>
                            }
                            <div className={`flex ${cardTextRef.current ? 'belowCardPart2' : 'belowCard'}`}>
                                <div className={`recordingBorder`}>
                                    <div className={`Column`}>
                                        <span className={`rightMargin`}><b>Recording</b></span>
                                    </div>
                                    <div
                                        data-testid="blinkingDot"
                                        className={
                                            fullRecording?.isRecording
                                                ? `Column blinking-dot ${appFontSize}-dot`
                                                : `Column steady-dot ${appFontSize}-dot`
                                        }
                                    />
                                </div>
                                <div className="fixedHeight35">
                                    {
                                        audiRecorder2Ref?.current?.mediaRecorder && (
                                            <ICMSTAudioVisualizer
                                                mediaRecorder={audiRecorder2Ref?.current?.mediaRecorder}
                                                height={waveFormHeight}
                                            />

                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            Loading...
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}

export default TestScreen;