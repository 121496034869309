import React, { useRef, } from "react";
import '../testScreen/TestScreen.css';
import ThreeScene from "../../components/animation/ThreeScene";
import Card from "../../components/card/Card";
import SampleContent from "../../components/sampleContent/SampleContent";
import '../summaryScreen/SummaryScreen.css';

const AvatarScreen = (props: any) => {
    // Extract the props from the parent component
    const {
        talkingDuration = 0, mode = "idle", timerValue, 
        cardTextRef = undefined, showTimer = false
    } = props;

    // Code to add the border to highlight the avatar animation while avatar is talking
    let avatarBorderClass = useRef("avatarBorder");
    if (cardTextRef?.current) {
        return (
            <div className="container summaryAvatarContainerPart2">
                <div className="column finContentCard" >
                    <Card
                        content={
                            <SampleContent screenName="AvatarScreen" text={cardTextRef?.current} />
                        }
                        cardClassName={`contentGrow finContentCard`}
                    />
                </div>

                <div className="column finContentCard summaryContent" >
                    <Card
                        content={
                            !showTimer 
                            ? (<div className="animContainerWithCard">
                                {/* Pass the updated talkingDuration and mode to ThreeScene */}
                                <ThreeScene talkingDuration={talkingDuration} mode={mode} />
                            </div>)
                            : <div className="timerScreen flex"> 
                                <div className="alignItemVertical">
                                    <span><h2>Preparation time left</h2></span>
                                    <h2 className="timerColor">{`${parseInt((timerValue / 60)?.toString())?.toString()?.padStart(2,'0')}:${parseInt((timerValue % 60)?.toString())?.toString()?.padStart(2,'0')}`}
                                    </h2>
                                </div>
                            </div>
                        }
                        cardClassName={`contentGrow finContentCard ${mode === "talking" ? avatarBorderClass.current : ''}`}
                    />
                </div>
            </div>
        )
    }
    else {
        return (
            <div className={`animContainer`}>
                {/* Pass the updated talkingDuration and mode to ThreeScene */}
                <ThreeScene talkingDuration={talkingDuration} mode={mode} />
            </div>
        )
    }

}

export default AvatarScreen;