import React, { useState } from "react";
import PersonalizationScreen from "./screens/PersonalizationScreen";
import "@fontsource-variable/mulish";
import "./App.css";
import "./common.css";

// Importing the necessary hooks for Redux
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import Header from "./components/header/Header";
import LoginScreen from "./screens/loginScreen/LoginScreen";
import SectionHeader from "./components/sectionHeader/SectionHeader";
import { loginTexts } from "./constants/constants";
import TestScreen from "./screens/testScreen/TestScreen";

const App = () => {
  const [ preChecksDone, setPreChecksDone ] = useState(false)
  const appFontSize = useSelector(
    (state: RootState) => state.appConfigSlice.appFontSize
  );
  const appTheme = useSelector(
    (state: RootState) => state.appConfigSlice.appTheme
  );
  const testTakerAccessToken = useSelector(
    (state: RootState) => state.appConfigSlice.testTaker.testTakerAccessToken
  );

  /**
   * Environment variable to determine whether the ILETS logo should be displayed or not in the application.
   * Which is managed by the the pipeline environment variable
   */
  const showILETSLogo = process.env.REACT_APP_SHOW_LOGO ?? "false";
  
  /**
   * UpdateFavicon is the function that changes the favicon icon for the browser tab.
   * @param iconPath is the path to the icon file to set to the browser tab icon.
   * which will be changed based on the current environment eg. DEV, QA, PROD etc .
   */
  const updateFavicon = (iconPath: string) => {
    const link: any = document.querySelector("link[rel='icon']");
    if (link) {
      link.href = iconPath;
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = iconPath;
      document.head.appendChild(newLink);
    }
  };
  /**
   * Set favicon and title of the applcation tab based on the showILETSLogo boolean value.
   * Default react app logo is used in the development environment by default.
   * Note: Make sure the ILETSLogo.svg must be present in the public folder.
   */
  if(showILETSLogo === "true"){
    updateFavicon("/ILETSLogo.svg");
    document.title = "ICMST";
  }

  return (
    <div className={`App`}>
      <div className={`appBlock ${appTheme} ${appFontSize}`}>
        { !testTakerAccessToken && 
          <div className={`centerBlock`}>
            <SectionHeader sectionHeaderName={loginTexts.LOGIN_SECTION_NAME} />
            <LoginScreen />
          </div>
        }
        { testTakerAccessToken && !preChecksDone ? (
          <>
            <Header showTimerAndTestTakerId={true}/>
            <PersonalizationScreen 
              setPreChecksDone={setPreChecksDone}
            />
          </>
         ):""}
         { testTakerAccessToken && preChecksDone ?(
            <TestScreen />
          ):""}
      </div>
    </div>
  );
};

export default App;
