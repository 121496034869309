import React from 'react';
import Card from '../../components/card/Card';
import RadioGroup from '../../components/radioGroup/RadioGroup';
import SampleContent from '../../components/sampleContent/SampleContent';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { changeAppFontSize } from '../../redux/appConfigSlice';

// Font selection screen component with radio buttons to change font size.
const FontSelection = () => {
    const fontSize = useSelector((state: RootState) => state.appConfigSlice.appFontSize);
    const dispatch: AppDispatch = useDispatch();

    const radioButtonOptions = [
        { id: 'small', className: 'smallOption', value: 'small', checked: false, label: 'ABC'},
        { id: 'medium', className: 'mediumOption', value: 'medium', checked: false, label: 'ABC'},
        { id: 'large', className: 'largeOption', value: 'large', checked: false, label: 'ABC'}
    ]
    let onChangeRadio = ( e:any ) => {
        // Redux action to update font size
        dispatch(changeAppFontSize(e.target.value));
    }

    return(
        <div className={`flex`}>
            <div>
                <Card
                    content= {
                        <RadioGroup
                            name={"fontSize"}
                            onChange={onChangeRadio}
                            options={ radioButtonOptions }
                            checkedValue={fontSize}
                        />
                    }
                />
            </div>
            <Card
                content={
                    <SampleContent screenName="FontScreen"/>
                }
                cardClassName='contentGrow'
            />
        </div>      
    )
};

export default FontSelection;

