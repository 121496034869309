import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "../src/redux/store";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import { createReactRouterV6Options, getWebInstrumentations, initializeFaro, ReactIntegration, ReactRouterVersion } from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { FaroRoutes } from '@grafana/faro-react';
export const REACT_APP_NAME: string =  process.env.REACT_APP_NAME ?? '';
export const REACT_APP_GRAFANA_URL: string =  process.env.REACT_APP_GRAFANA_URL ?? '';


// Initialize Faro
initializeFaro({
  url: REACT_APP_GRAFANA_URL,
  app: {
    name: REACT_APP_NAME,
    version: '1.0.0',
    environment: 'production'
  },
  instrumentations: [
    ...getWebInstrumentations(),
    new TracingInstrumentation(),
    new ReactIntegration({
      router: createReactRouterV6Options({
        createRoutesFromChildren,
        matchRoutes,
        Routes,
        useLocation,
        useNavigationType,
      }),
    }),
  ],
});



const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
        <Router>
          <FaroRoutes>
        <Route path="/" element={<App />} />
        {/* Add other routes here */}
      </FaroRoutes>
        </Router>      
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

