import  { useRef, useState, useEffect } from "react";

const AudioPlayer = (src: string) => {
  const audioRef = useRef<HTMLAudioElement>(new Audio(src));
  const [isPlaying, setIsPlaying] = useState(false);
//   const [volume, setVolume] = useState(1)  --commented line

  useEffect(() => {
    const audio = audioRef.current;
    const handleEnded = () => {
      setIsPlaying(false);
      console.log("Audio playing is ENDED.");
    };
    audio.addEventListener("ended", handleEnded);
    return () => {
      audio.removeEventListener("ended", handleEnded);
    };
  }, []);

  const playAudio = () => {
    audioRef.current.play();
    setIsPlaying(true);
    console.log("Audio playing is STARTED.");
  };

  return {
    playAudio,
    // pauseAudio,
    // stopAudio,
    // changeVolume,
    isPlaying,
    // volume,
    // audioRef
  };
};
export default AudioPlayer;
