const _ = require('lodash');


const calculateRMS = ( arrayData: any) => {
    let squareValue = _.reduce( arrayData, (rmsValue:any , ele:any) => rmsValue + (ele * ele), 0.0);
    return Math.sqrt(squareValue / arrayData.length)

}


export const fetchMeanValue = async (audioBlob?: any) => {
    if (audioBlob) {
        const audioContext = new (window.AudioContext)();
        const arrayBuffer = await audioBlob.arrayBuffer();
        // Decode the audio data
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        const samples = audioBuffer.getChannelData(0);
        let filteredVoiceData =_.filter(samples, (value: any) => value > 0);
        let meanValue = calculateRMS(filteredVoiceData);
        console.log('Mean value: ', meanValue);
        return meanValue;

    } else {
        console.log('No audio data available.');
    }
}


export const isSilenceDetectedForGivenTime = ( audioRecorder: any, time: any, silenceThreshold: any ) => {
    if( audioRecorder?.mediaRecorder) {
        const audioContext = new AudioContext();
        const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(audioRecorder?.mediaRecorder?.stream);
        const analyserNode = audioContext.createAnalyser();
        mediaStreamAudioSourceNode.connect(analyserNode);
        const pcmData = new Float32Array(analyserNode.fftSize);
        let audioData:any = [];
        const onFrame = () => {
            analyserNode.getFloatTimeDomainData(pcmData);
            let resultValue = calculateRMS( pcmData)
            audioData.push(resultValue);
        }
        let intervalId = window.setInterval(() => {
            onFrame()
        },100);

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(checkForSilence(audioData, silenceThreshold, mediaStreamAudioSourceNode, analyserNode));
                clearInterval( intervalId)
            }, time)
        })
    }
}

const checkForSilence = (audioData: any, silenceThreshold: any, mediaStreamAudioSourceNode:any,  analyserNode:any) => {
    let isSilence = false;
    mediaStreamAudioSourceNode?.disconnect();
    analyserNode?.disconnect()
    let talkingData =  _.filter(audioData, (ele: any) =>  ele > silenceThreshold);
    if( talkingData.length > Math.floor(audioData.length * .30) ) {
        return isSilence
    } else {
        isSilence = true;
        return isSilence;
    }

}